import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/dist/client/router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store/feature/authSlice';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import axios from 'axios';
import * as EmailValidator from 'email-validator';

import {
  cartList,
  cartSelector,
  checkout,
  clearCartState,
  EphemeralKey,
  getAddress,
  getStorageHubAddress,
  paymentIntent,
  paymentMethods,
  saveAddress,
  save_address,
  shippingMethods,
  getSendCloudShipments,
  clearShipmentMethod,
} from '../../store/feature/cartSlice';
import CartItemBox from '../../components/Cart/CartItemBox/CartItemBox';
import OrderSummary3 from '../../components/Cart/OrderSummary/OrderSummary3';
import PaymentMethod2 from '../../components/Cart/PaymentMethods/PaymentMethod2';
import OutsideClickHandler from 'react-outside-click-handler';
import PopUp from '../../components/Shared/PopUp/PopUp';
import Modal from '../../components/Shared/Modal/Modal';
import OrderSuccess from '../../components/Cart/OrderSuccess/OrderSuccess';
import NoCartItem from '../../components/Cart/NoCartItem/NoCartItem';
import AddressForm from '../../components/Cart/AddressForm/AddressForm';
import ShippingAddresses from '../../components/Cart/ShippingAddresses/ShippingAddresses';
import StorageHubAddresses from '../../components/Cart/StorageHubAddress/StorageHubAddresses';
import CustomLoading from '../../components/Shared/Loading/CustomLoading';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { Checkout_text } from '../../constant/Translation/Cart_text';
import { Address_text } from '../../constant/Translation/Address';
import { Shipping_text } from '../../constant/Translation/Shipping_text';
import { Payment_text } from '../../constant/Translation/Payment';
import { push_data_layer } from '../../constant/GTMDatalayer';
import ShipmentDetails2 from '../../components/Cart/ShipmentDetails/ShipmentDetails2';
import { base_url } from '../../constant/url';
import {
  commissionsSelector,
  getCommissions,
} from '../../store/feature/Commissions/CommissionsSlice';
import { dynamic_translation_set } from '../../constant/Translation/translation_set';
import CartCommission2 from '../../components/Cart/CartCommission/CartCommission2';
import ScheduleSelect2 from '../../components/Cart/SchedulePart/ScheduleSelect2';
import { getDatesArray } from '../../components/Shared/Constant/Constant';
import { useScreenSize } from '../../hooks/useScreenSize';
import { Listing_text } from '../../constant/Translation/Listing';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import ShippingMethod2 from '../../components/Cart/ShippingMethods/ShippingMethod2';
import Heading2Block from '../../components/StaticBlocks/Heading2Block';
import DividerBlock from '../../components/StaticBlocks/DividerBlock';
import EmptyBlock from '../../components/StaticBlocks/EmptyBlock';
import GuestInformation from '../../components/Cart/CartAttributes/CartAttributesFrom';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import GuestShippingAddress from '../../components/Cart/ShippingAddresses/GuestShippingAddress';
import { checkout_submit } from '../../functions/checkout/checkout.function';
import { guest_info_block } from './DefaultBlocks/CheckoutDefaultBlocks';
import { insertDefaultBlockInSchema } from '../../hooks/insertDefaultBlockInSchema';
import PayWith from '../../components/Cart/PayWith/PayWith';
import { WalletSelector } from '../../store/feature/wallet/walletSlice';
import ShipmentMethod from '../../components/Cart/ShipmentMethod/ShipmentMethod';
import {
  editor_versions_list,
  latest_editor_version,
} from '../../constant/Version';
import DemandCommission from '../../components/Cart/DemandCommission/DemandCommission';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';
import OPPSetup from '../../components/Opp/OPPSetup';
import StripeSetup from '../../components/Stripe/StripeSetup';
import AnonymousDonation from '../../components/Cart/Donation/AnonymousDonation';
import { common_blocks_cases } from './BlockDecide/CommonBlockCase';
import AnimatedTextBlock from '../../components/StaticBlocks/AnimatedTextBlock';
import CheckOutButton from '../../components/Cart/Checkout/CheckOutButton';
import CheckoutLatestButton from '../../components/Cart/Checkout/CheckoutLatestButton';
import CartAttributes from '../../components/Cart/CartAttributes/CartAttributesFrom';

const CheckoutPageLayout = ({ type }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { register, handleSubmit, setValue, control } = useForm();
  const { width, height } = useScreenSize();

  // const CheckoutPageFormat = insertDefaultBlockInSchema({
  //   full_page_format: TYPE_CONSTANT.PAGES?.checkout,
  //   selected_block: guest_info_block,
  //   block_code: 'guest_information',
  //   row_index: 0,
  //   column_index: 0,
  // });

  const CheckoutPageFormat = TYPE_CONSTANT.PAGES?.checkout;

  //checking is guest info available or no

  const specialCheckoutTypes = ['appointments', 'events', 'donation'];

  // Main state
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);
  const [isDonateAnonymously, setIsDonateAnonymously] = useState(false);
  const [item_attributes, setItemAttributes] = useState([]);

  // Popup related
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error_message, setError_message] = useState('');

  // Address related
  const [guestShippingAddress, setGuestShippingAddress] = useState(null);
  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false);
  const [selectShippingAddress, setSelectShippingAddress] = useState(null);
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [selectStorageHubAddress, setSelectStorageHubAddress] = useState(null);

  // shipment methods related
  const [selected_shipment_method, setSelectedShipmentMethod] = useState(null);

  // Cart commission
  const [cart_commission, setCartCommission] = useState([]);
  // Demand commission
  const [demand_commission, setDemandCommission] = useState([]);

  //guest_information
  const [guest_information, setGuestInformation] = useState({ email: '' });
  const [guest_enabled, setIsGuestEnabled] = useState(false);

  // Schedule state
  let dates = getDatesArray();
  const [selectedDate, setSelectedDate] = useState(
    moment(dates[0]).format('YYYY-MM-DD')
  );
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [scheduleArray, setScheduleArray] = useState(null);
  const [selectedScheduleTimeIndex, setSelectedScheduleTimeIndex] =
    useState(null);
  const [selected_pricing, setSelectedPricing] = useState(null);

  //

  //States from selector
  const { auth_key } = useSelector(authSelector);
  const { order_reference, addresses, storage_hub_addresses, cart_attributes } =
    useSelector(cartSelector);
  const { commissions } = useSelector(commissionsSelector);

  //First side effect calling
  useEffect(() => {
    const cart_list_params = {};
    if (shippingMethod !== null && shippingMethod) {
      cart_list_params.bodyParam = {
        shipping_method_id: shippingMethod?.id,
      };
    }

    dispatch(cartList(cart_list_params));

    if (
      TYPE_CONSTANT.ACCOUNTS_CONFIGS?.enable_shipping_methods_preference !==
        true &&
      // !specialCheckoutTypes.includes(checkoutType) &&
      checkoutType !== 'digital'
    ) {
      dispatch(shippingMethods({}));
    }

    dispatch(paymentMethods({}));
    dispatch(EphemeralKey({}));
    dispatch(
      getStorageHubAddress({
        bodyParam: { type: 'storage_hub' },
      })
    );
    dispatch(getCommissions({ bodyParam: { type: 'cart' } }));
  }, [checkoutType]);

  // Api call base on authkey
  useEffect(() => {
    if (auth_key) {
      dispatch(
        getAddress({
          bodyParam: { type: 'shipping' },
        })
      );
    }
  }, [auth_key]);

  // cartSelector from redux store
  const {
    cart,
    cart_details,
    shipments,
    payment_methods,
    shipping_methods,
    isError,
    errorMessage,
    isFetching,
    isCheckoutFetching,
    isSuccess,
    send_cloud_shipment_methods,
  } = useSelector(cartSelector);
  8;
  const { mangopay_balance } = useSelector(WalletSelector);

  //checkoutType (checkoutType first check from  listing of cart because all the listings will bé same type )
  const checkoutType =
    cart_details?.length > 0
      ? cart_details[0]?.listing?.order_type
        ? cart_details[0]?.listing?.order_type
        : router?.query?.type
        ? router?.query?.type
        : type
        ? type
        : 'listings'
      : 'listings';

  // calling schedule
  useEffect(() => {
    if (
      specialCheckoutTypes.includes(checkoutType) &&
      checkoutType !== 'donation'
    ) {
      if (cart_details && selectedDate) {
        axios
          .post('/api/schedules', {
            id: `${cart_details[0]?.listing?.id}`,
            bodyParam: { days: 30, start_at: selectedDate },
          })
          .then((res) => {
            setScheduleArray(res.data.schedules_per_day);
          });
      }
    }
  }, [cart_details, selectedDate]);

  // Calling payment method when shipping methods  preference enabled
  useEffect(() => {
    if (
      TYPE_CONSTANT.ACCOUNTS_CONFIGS?.enable_shipping_methods_preference &&
      cart_details?.length > 0 &&
      !specialCheckoutTypes.includes(checkoutType) &&
      checkoutType !== 'digital'
    ) {
      dispatch(
        shippingMethods({
          type: 'account',
          account_id: cart_details[0]?.listing?.account?.id,
        })
      );
    }
  }, [cart_details]);

  // calling send cloud shipment method when select send send cloud shipment channel
  useEffect(() => {
    if (
      shippingMethod?.channel === 'sendcloud' &&
      selectShippingAddress?.country_code
    ) {
      dispatch(
        getSendCloudShipments({
          shipping_method_id: shippingMethod?.id,
          country: selectShippingAddress?.country_code,
        })
      );
    } else {
      dispatch(clearShipmentMethod({}));
    }
  }, [shippingMethod, selectShippingAddress]);

  //Effect for set first time shipping method ,payment method , and addresses
  useEffect(() => {
    if (payment_methods && paymentMethod == null) {
      setPaymentMethod(payment_methods[0]);
    }
    if (shipping_methods && shippingMethod == null) {
      setShippingMethod(shipping_methods[0]);
    }
    if (
      addresses &&
      selectShippingAddress == null &&
      shippingMethod?.type === 'delivery'
    ) {
      setSelectShippingAddress(addresses[0]);
    }
    if (
      storage_hub_addresses &&
      selectStorageHubAddress == null &&
      shippingMethod?.type === 'storage_hub'
    ) {
      setSelectStorageHubAddress(storage_hub_addresses[0]);
    }
  }, [
    payment_methods,
    shipping_methods,
    addresses,
    storage_hub_addresses,
    shippingMethod,
  ]);

  // cart api call when change delivery type
  useEffect(() => {
    if (shippingMethod?.id) {
      fetch_cart_list();
    }
  }, [shippingMethod]);

  // cart api call when change delivery address
  useEffect(() => {
    if (selectShippingAddress?.id) {
      fetch_cart_list();
    }
  }, [selectShippingAddress]);

  //Main Checkout func:
  const clickCheckOut = () => {
    if (!guest_enabled && !auth_key) {
      router.push('/sign-in?to=/checkout');
      return false;
    }
    checkout_submit({
      cart_details,
      cart,
      specialCheckoutTypes,
      checkoutType,
      selectedScheduleTimeIndex,
      setShowError,
      commissions,
      setError_message,
      auth_key,
      selectShippingAddress,
      shippingMethod,
      selectStorageHubAddress,
      scheduleArray,
      selectedDateIndex,
      cart_commission,
      paymentMethod,
      dispatch,
      setShowSuccessMessage,
      guest_information,
      guestShippingAddress,
      router,
      mangopay_balance,
      selected_shipment_method,
      demand_commission,
      isDonateAnonymously,
      item_attributes,
      cart_attributes,
    });
  };

  // save address
  const onSubmit = (data) => {
    if (!!auth_key) {
      const id = !isNewAddress ? selectShippingAddress.id : '';
      dispatch(
        save_address({
          id,
          addressData: {
            address: {
              ...Object.fromEntries(
                Object.entries(data).filter(([key, value]) => value !== '')
              ),
              type: 'shipping',
            },
          },
        })
      ).then((res) => {
        if (!res.payload.code) {
          dispatch(
            getAddress({
              bodyParam: { type: 'shipping' },
            })
          );
          setShowShippingAddressForm(false);
        }
      });
    } else {
      setGuestShippingAddress({
        ...data,
        formatted_address: `${data.name}, ${data?.address_line_1}, ${data?.post_code}, ${data?.state}, ${data?.country}`,
      });
      setShowShippingAddressForm(false);
    }
  };

  // close popup
  const closePopUP = () => {
    dispatch(clearCartState());
    setShowError(false);
    setError_message('');
  };

  //fetch cart api calling base on commission effect
  useEffect(() => {
    if (cart_commission?.length > 0) {
      fetch_cart_list();
    }
  }, [cart_commission]);

  //fetch cart api calling base on demand commission effect
  useEffect(() => {
    if (demand_commission?.length > 0) {
      fetch_cart_list();
    }
  }, [demand_commission]);
  //fetch cart api calling base on shipment method   effect
  useEffect(() => {
    if (selected_shipment_method) {
      fetch_cart_list();
    }
  }, [selected_shipment_method]);

  //fetch_cart_list
  const fetch_cart_list = ({}) => {
    let cart_list_params = {};
    if (shippingMethod !== null && shippingMethod) {
      cart_list_params.bodyParam = {
        shipping_method_id: shippingMethod?.id,
      };

      if (shippingMethod?.type === 'delivery') {
        if (selectShippingAddress?.id) {
          cart_list_params.bodyParam.shipping_address_id =
            selectShippingAddress?.id;
        }
        if (selected_shipment_method?.id) {
          cart_list_params.bodyParam.external_shipping_method_id =
            selected_shipment_method?.id;
        }
      }
    }

    //
    if (cart_commission !== null && cart_commission?.length > 0) {
      cart_list_params.bodyParam = {
        ...cart_list_params.bodyParam,
        cart_commission: JSON.stringify(cart_commission),
      };
    }
    if (demand_commission !== null && demand_commission?.length > 0) {
      cart_list_params.bodyParam = {
        ...cart_list_params.bodyParam,
        demand_commission: JSON.stringify(demand_commission),
      };
    }

    dispatch(cartList(cart_list_params));
  };

  // component decide
  const component_decide = ({
    block,
    block_name,
    block_code,
    isStatic,
    data,
    styles,
    isEnabled = true,
    current_editor_version,
    order_number,
    block_data_key,
    fetch_by,
    extra_data,
  }) => {
    const identifier = block ?? block_name;
    const order_type = data?.order_type;

    const section = {
      order_number,
      block,
      block_data_key,
      fetch_by,
      data,
      isStatic,
      extra_data,
      styles,
    };

    switch (identifier) {
      case 'my_cart_list':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <div style={get_dynamic_styles(styles?.main_styles)}>
              <p className="text-primary text-xl leading-6 font-medium mb-5">
                {Checkout_text.cart.header_title}
              </p>
              <CartItemBox
                cart={cart}
                cart_details={cart_details}
                fetch_cart_list={fetch_cart_list}
              />
            </div>
          )
        );
      case 'shippings':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <>
              <ShippingMethod2
                shipping_methods={shipping_methods}
                shippingMethod={shippingMethod}
                setShippingMethod={setShippingMethod}
                setSelectedShipmentMethod={setSelectedShipmentMethod}
                setSelectShippingAddress={setSelectShippingAddress}
                styles={styles}
              />

              {shippingMethod?.type === 'delivery' && (
                <div style={get_dynamic_styles(styles?.main_styles)}>
                  <p className="text-primary text-xl leading-6 font-medium ">
                    {shippingMethod?.name} {Checkout_text.cart.address}
                  </p>
                  {addresses?.length > 0 && (
                    <div className=" mt-5">
                      <ShippingAddresses
                        addresses={addresses}
                        selectShippingAddress={selectShippingAddress}
                        setSelectShippingAddress={setSelectShippingAddress}
                        setSelectedShipmentMethod={setSelectedShipmentMethod}
                      />
                    </div>
                  )}
                  {/* Guest Shipping Address */}
                  {guestShippingAddress && !auth_key && (
                    <div className=" mt-5">
                      <GuestShippingAddress
                        shipping_address={guestShippingAddress}
                      />
                    </div>
                  )}
                  {/* By thiśsame buttonn will work for checkout anďguest checkout  */}
                  <div className="mt-5 flex justify-start flex-wrap gap-5">
                    {(auth_key || !guestShippingAddress) && (
                      <button
                        className=" btn-primary-outline px-4 py-2 "
                        onClick={() => {
                          setShowShippingAddressForm(true),
                            setIsNewAddress(true);
                        }}
                      >
                        {Checkout_text.cart.add_new_address}
                      </button>
                    )}
                    {(selectShippingAddress || guestShippingAddress) && (
                      <button
                        className="btn-primary-outline px-4 py-2 "
                        onClick={() => {
                          setShowShippingAddressForm(true),
                            setIsNewAddress(false);
                        }}
                      >
                        {Address_text.address.edit_address}
                      </button>
                    )}
                  </div>
                </div>
              )}
              {shippingMethod?.type === 'storage_hub' && (
                <div style={get_dynamic_styles(styles?.main_styles)}>
                  <p className="text-primary text-xl leading-6 font-medium ">
                    {shippingMethod?.name} {Checkout_text.cart.address}
                  </p>
                  <div className=" mt-5">
                    <StorageHubAddresses
                      addresses={storage_hub_addresses}
                      selectStorageHubAddress={selectStorageHubAddress}
                      setSelectStorageHubAddress={setSelectStorageHubAddress}
                    />
                  </div>
                </div>
              )}
            </>
          )
        );

      case 'payments':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <PaymentMethod2
              payment_methods={payment_methods}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              styles={styles}
            />
          )
        );

      case 'shipments_method':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) &&
          shippingMethod?.channel === 'sendcloud' && (
            <ShipmentMethod
              shipment_methods={send_cloud_shipment_methods}
              selectedShipmentMethod={selected_shipment_method}
              setSelectedShipmentMethod={setSelectedShipmentMethod}
              selectedShippingMethod={shippingMethod}
              selectShippingAddress={selectShippingAddress}
              styles={styles}
            />
          )
        );
      case 'shipments':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <ShipmentDetails2
              cart={cart}
              cart_details={cart_details}
              shipments={shipments}
              styles={styles}
              current_editor_version={current_editor_version}
            />
          )
        );

      case 'cart_commissions':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <CartCommission2
              cart_commission={cart_commission}
              setCartCommission={setCartCommission}
              fetch_cart_list={fetch_cart_list}
              styles={styles}
            />
          )
        );
      case 'demand_commission':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <DemandCommission
              demand_commission={demand_commission}
              setDemandCommission={setDemandCommission}
              fetch_cart_list={fetch_cart_list}
              styles={styles}
            />
          )
        );

      case 'order_summary':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <>
              <OrderSummary3
                cart={cart}
                cart_details={cart_details}
                shippingMethod={shippingMethod}
                cart_commission={cart_commission}
                setCartCommission={setCartCommission}
                fetch_cart_list={fetch_cart_list}
                styles={styles}
              />
            </>
          )
        );
      case 'guest_information':
        return (
          isEnabled &&
          !auth_key &&
          order_type?.includes(checkoutType) && (
            <GuestInformation
              cart={cart}
              cart_details={cart_details}
              shippingMethod={shippingMethod}
              guest_information={setGuestInformation}
              setGuestInformation={setGuestInformation}
              styles={styles}
              data={data}
              setIsGuestEnabled={setIsGuestEnabled}
            />
          )
        );
      case 'schedules':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <ScheduleSelect2
              dates={dates}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedDateIndex={selectedDateIndex}
              setSelectedDateIndex={setSelectedDateIndex}
              scheduleArray={scheduleArray}
              selectedScheduleTimeIndex={selectedScheduleTimeIndex}
              setSelectedScheduleTimeIndex={setSelectedScheduleTimeIndex}
              styles={styles}
            />
          )
        );

      case 'checkout_button':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) &&
          cart &&
          (data?.version ? (
            <CheckoutLatestButton
              editor_versions_list={editor_versions_list}
              current_editor_version={current_editor_version}
              clickCheckOut={clickCheckOut}
              isCheckoutFetching={isCheckoutFetching}
              data={data}
              styles={styles}
            />
          ) : (
            <CheckOutButton
              editor_versions_list={editor_versions_list}
              current_editor_version={current_editor_version}
              clickCheckOut={clickCheckOut}
              isCheckoutFetching={isCheckoutFetching}
              data={data}
              styles={styles}
            />
          ))
        );

      case 'pay_with':
        return (
          <PayWith
            selected_payment_method={paymentMethod}
            data={data}
            styles={styles}
            wallet_info={{}}
          />
        );
      case 'heading2_block':
        return <Heading2Block data={data} styles={styles} />;
      case 'divider_block':
        return <DividerBlock styles={styles} />;
      case 'empty_block':
        return <EmptyBlock data={data} styles={styles} />;
      case 'opp_setup_button':
        return isEnabled && <OPPSetup data={data} styles={styles} />;
      case 'stripe_setup_button':
        return isEnabled && <StripeSetup data={data} styles={styles} />;
      case 'anonymous_donation_checkup':
        return (
          isEnabled && (
            <AnonymousDonation
              isDonateAnonymously={isDonateAnonymously}
              setIsDonateAnonymously={setIsDonateAnonymously}
              data={data}
              styles={styles}
            />
          )
        );
      case 'animated_paragraph':
        return <AnimatedTextBlock data={data} styles={styles} />;
      case 'cart_item_attributes':
        return (
          isEnabled &&
          order_type?.includes(checkoutType) && (
            <CartAttributes
              data={data}
              styles={styles}
              item_attributes={item_attributes}
              setItemAttributes={setItemAttributes}
              cart_details={cart_details}
              isEnabled={isEnabled}
            />
          )
        );
      default:
        return common_blocks_cases({ section });
    }
  };

  return (
    <div
      style={get_dynamic_styles(
        responsive_design_styles({
          parent_group: CheckoutPageFormat?.styles,
          child_group_name: 'main_styles',
          width,
        })
      )}
    >
      {isFetching && <CustomLoading />}
      {(showError || isError) && (
        <OutsideClickHandler
          onOutsideClick={() => {
            (showError || isError) &&
              (setShowError(false),
              setError_message(''),
              dispatch(clearCartState()));
          }}
        >
          <div className="fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5">
            <div className="">
              <PopUp
                message={error_message || errorMessage}
                closePopUP={closePopUP}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
      {showSuccessMessage && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowSuccessMessage(false);
              router.push('/');
            }}
          >
            <OrderSuccess />
          </OutsideClickHandler>
        </Modal>
      )}
      {showShippingAddressForm && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowShippingAddressForm(false);
            }}
          >
            <AddressForm
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              setShowShippingAddressForm={setShowShippingAddressForm}
              isNewAddress={isNewAddress}
              selectShippingAddress={
                selectShippingAddress || guestShippingAddress
              }
              setValue={setValue}
              control={control}
            />
          </OutsideClickHandler>
        </Modal>
      )}

      {cart_details === null || cart_details?.length > 0 ? (
        <div
          className="flex flex-col "
          style={get_dynamic_styles(
            responsive_design_styles({
              parent_group: CheckoutPageFormat?.styles,
              child_group_name: 'box_styles',
              width,
            })
          )}
        >
          {/* Rows */}

          {CheckoutPageFormat?.schema?.map((row) => {
            const {
              columns,
              current_editor_version = '',
              ...column_styles
            } = row || {};
            const row_styles = responsive_row_styles({
              parent_group: row,
              width,
            });

            return (
              <div
                className={`grid `}
                style={
                  current_editor_version == latest_editor_version
                    ? {
                        display: row_styles?.display,
                        gridTemplateColumns: dynamic_text_convert({
                          main_text: row_styles?.columns_box_percentage,
                          check_by: '-1%',
                          value: '',
                        }),
                        ...get_dynamic_styles(row_styles),
                      }
                    : {
                        display:
                          row_styles?.isShowInMobile === false
                            ? 'none'
                            : 'grid',
                        gap: row_styles?.gap_between_columns ?? '24px',
                        gridTemplateColumns:
                          width > 768
                            ? row_styles?.columns_box_percentage
                              ? row_styles?.columns_box_percentage
                              : `repeat(${
                                  row.columns?.length ?? 1
                                }, minmax(0, 1fr))`
                            : row?.['768_row_styles']
                            ? row_styles?.columns_box_percentage
                            : '100%',
                        ...get_dynamic_styles(row_styles),
                      }
                }
              >
                {/* Columns */}
                {columns?.map((column, index) => {
                  const current_column_style = responsive_column_styles({
                    parent_group: column_styles,
                    index,
                    width,
                  });

                  return (
                    <div
                      style={{
                        display: current_column_style?.display
                          ? current_column_style?.display
                          : 'flex',
                        ...get_dynamic_styles(current_column_style),
                      }}
                    >
                      {column?.map((item) => {
                        return (
                          component_decide({
                            ...item,
                            current_editor_version,
                          }) &&
                          component_decide({ ...item, current_editor_version })
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <NoCartItem />
        </div>
      )}
    </div>
  );
};

export default CheckoutPageLayout;
